<template>
  <div
    class="result-wraper w-full flex flex-wrap justify-center overflow-hidden h-full"
  >
    <div
      class="w-full max-h-9/10-screen overflow-hidden sm:w-9/10 lg:w-8/10 xl:w-8/10 flex flex-wrap items-start z-0 px-5 flex-wrap"
    >
      <div class="w-full title text-primary font-bold my-5 sm:my-16">
        <p
          class="text-xl font-extrabold uppercase text-center sm:text-4xl w-full"
        >
          Datos de pago y facturación
        </p>
      </div>
      <div
        class="w-full sm:w-6/12 px-0 sm:px-5 h-vh-6/10 flex flex-col font-bold text-primary text-xl sm:text-2xl"
      >
        <div
          class="w-full text-right flex flex-wrap justify-end items-center mb-3 align-end relative mb-8 sm:mb-3"
        >
          <label
            for="name"
            class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end sm:relative sm:left-0 sm:top-0"
            >Nombre</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
        <div
          class="w-full text-right flex flex-wrap justify-end items-center mb-3 align-end relative mb-8 sm:mb-3"
        >
          <label
            for="name"
            class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end sm:relative sm:left-0 sm:top-0"
            >Apellidos</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
        <div
          class="w-full text-right flex flex-wrap justify-end items-center mb-3 align-end relative mb-8 sm:mb-3"
        >
          <label
            for="name"
            class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end sm:relative sm:left-0 sm:top-0"
            >E-mail</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
        <div
          class="w-full text-right flex flex-wrap justify-end items-center mb-3 align-end relative mb-8 sm:mb-3"
        >
          <label
            for="name"
            class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end sm:relative sm:left-0 sm:top-0"
            >Dirección</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
        <div class="w-full my-7 hidden sm:block"></div>
        <div class="w-full text-right flex flex-wrap justify-start">
          <div
            class="w-6/12 text-left flex flex-wrap justify-start items-center align-end relative mb-8 sm:mb-3"
          >
            <label
              for="name"
              class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end mr-5 sm:relative sm:left-0 sm:top-0"
              >Interior/Depto.</label
            >
            <div class="w-full sm:w-24">
              <input
                type="text"
                class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
              />
            </div>
          </div>
          <div
            class="w-6/12 text-right flex flex-wrap justify-start items-center align-end relative mb-8 sm:mb-3"
          >
            <label
              for="name"
              class="absolute left-1 -top-6 w-20 sm:w-auto ml-5 leading-5 text-left flex items-end mr-5 sm:relative sm:left-0 sm:top-0"
              >C.P.</label
            >
            <div class="w-full sm:w-32">
              <input
                type="text"
                class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
              />
            </div>
          </div>
        </div>
        <div
          class="w-full text-right flex flex-wrap justify-end items-center align-end relative mb-8 sm:mb-3"
        >
          <label
            for="name"
            class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end sm:relative sm:left-0 sm:top-0"
            >País</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
        <div
          class="w-full text-right flex flex-wrap justify-end items-center align-end relative mb-8 sm:mb-3"
        >
          <label
            for="name"
            class="absolute left-1 -top-6 w-20 sm:w-44 leading-5 text-left flex items-end sm:relative sm:left-0 sm:top-0"
            >Ciudad</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
      </div>

      <div
        class="w-full sm:w-6/12 sm:px-5 sm:h-vh-6/10 overflow-hidden flex flex-col font-bold text-primary text-xl sm:text-2xl sm:border-l-4 sm:border-primary pt-10 sm:pt-0"
      >
        <div
          class="w-full text-right flex flex-wrap justify-end items-center mb-3"
        >
          <div class="w-9 h-9 mr-3">
            <label
              class="radio cursor-pointer w-9 h-9 relative flex justify-center items-center rounded-full"
              @click="$refs['pay_card'].checked = true"
            >
              <input
                ref="pay_card"
                type="radio"
                name="age"
                value="1"
                checked="true"
              />
              <div
                class="radio-center w-5 h-5 rounded-full absolute"
                aria-hidden="true"
              ></div>
            </label>
          </div>
          <div
            for="name"
            class="leading-5 flex-1 flex justify-between items-center"
          >
            <p class="max-w-full text-center">Tarjeta de Crédito/Débito</p>
            <img src="../assets/img/tarjetas.png" class="w-32" />
          </div>
        </div>
        <div class="w-full text-right justify-end mb-3 align-end px-5">
          <label for="name" class="w-44 leading-8 text-left flex items-end"
            >No. de Tarjeta</label
          >
          <div class="flex-1 max-w-full">
            <input
              type="text"
              class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
            />
          </div>
        </div>
        <div class="w-full text-right flex flex-wrap justify-start mb-3 px-5">
          <div class="w-6/12 text-left justify-start mb-3 align-end">
            <label for="name" class="w-44 leading-8 text-left flex items-end"
              >Vigencia</label
            >
            <div class="w-32">
              <input
                type="text"
                class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
                placeholder="MM/YY"
              />
            </div>
          </div>
          <div class="w-6/12 text-right justify-start mb-3 align-end">
            <label for="name" class="w-15 leading-8 text-left flex items-end"
              >CVV</label
            >
            <div class="w-32">
              <input
                type="text"
                class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0 shadow border border-primary-light"
              />
            </div>
          </div>
        </div>
        <div class="my-3 w-full"></div>
        <div
          class="w-full text-right flex flex-wrap justify-end items-center mb-3"
        >
          <div class="w-9 h-9 mr-3">
            <label
              class="radio cursor-pointer w-9 h-9 relative flex justify-center items-center rounded-full"
              @click="$refs['paypal'].checked = true"
            >
              <input ref="paypal" type="radio" name="age" value="2" />
              <div
                class="radio-center w-5 h-5 rounded-full absolute"
                aria-hidden="true"
              ></div>
            </label>
          </div>
          <div
            for="name"
            class="leading-5 flex-1 flex justify-start items-center"
          >
            <img src="../assets/img/paypal.png" class="w-32" />
          </div>
        </div>
        <div class="w-full flex justify-center mb-5">
          <button
            class="bg-special-3 py-2 px-10 rounded-md text-primary text-2xl w-full sm:w-auto"
            @click="
              updateShoppingList([]);
              next();
            "
          >
            Seguir Comprando
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "PaymentCollectionPage",
  data: function() {
    return {
      shopingItems: []
    };
  },
  components: {},
  props: [],
  computed: {
    total() {
      let self = this;
      if (self.shopingItems.length == 1) {
        return self.shopingItems[0].result.filter(function(item) {
          return item.id == self.shopingItems[0].packageSelectedId;
        })[0].price;
      } else if (self.shopingItems.length >= 2) {
        return self.shopingItems.reduce(function(a, b) {
          if (a.result) {
            return (
              a.result.filter(function(item) {
                return item.id == a.packageSelectedId;
              })[0].price +
              b.result.filter(function(item) {
                return item.id == b.packageSelectedId;
              })[0].price
            );
          } else {
            return (
              parseInt(a) +
              b.result.filter(function(item) {
                return item.id == b.packageSelectedId;
              })[0].price
            );
          }
        });
      } else {
        return 0;
      }
    },
    shopingList() {
      return this.$store.state.shoppingList;
    }
  },
  mounted() {
    this.getcarProducts();
  },
  methods: {
    async getcarProducts() {
      let self = this;
      let ids = self.shopingList.map(function(item) {
        return item.id;
      });
      self.$store.dispatch("getCarProducts", ids).then(result => {
        self.updateShoppingList(result);
        self.shopingItems = result;
      });
    },
    next() {
      this.$router.push("/");
    },
    ...mapMutations(["updateShoppingList"]),
    removeFromShoopingCar(item) {
      for (var i = 0; i < this.shopingItems.length; i++) {
        if (item.id == this.shopingItems[i].id) {
          this.shopingItems.splice(i, 1);
          i--;
        }
      }
      this.updateShoppingList(this.shopingItems);
    }
  }
};
</script>

<style >
.radio {
  position: relative;
}

.radio input {
  appearance: none !important;
}

.radio {
  box-shadow: inset -1px -1px 4px rgb(255, 255, 255),
    inset 1px 1px 4px rgba(120, 120, 120, 0.8);
}

.radio-center {
  background: white;
}
.radio input:checked + .radio-center {
  background: var(--primary) !important;
}
</style>
